import React, { useState } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import clsx from "clsx"
import "lazysizes"
import { graphql } from "gatsby"
import { Location } from "@reach/router"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import MenuItems from "../components/departments/MenuItems"
import Navigation from "../components/Navigation"
import SectionTitle from "../components/SectionTitle"
import GeneralContact from "../components/contacts/GeneralContact"
import ContactPopup from "../components/contacts/ContactPopup"
import LocationMapCard from "../components/cards/LocationMapCard"
import TeamMembersCarousel from "../components/carousels/TeamMembersCarousel"

import "../styles/departmentCategory.scss"

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    marginBottom: 5,
  },

  departmentSeparator: {
    height: 100,
    display: "block",
  },
  icon: {
    marginRight: 15,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center left",
    color: "transparent",
    height: 60,
    width: 60,
  },

  headingText: {
    color: "#fff",
    fontWeight: 600,
    textAlign: "left",
    fontSize: 24,
  },
  map: {
    height: 480,
  },
  mapToolTip: {
    background: "none",
    border: "none",
    boxShadow: "none",

    "&::before": {
      content: "",
      borderColor: "transparent",
    },
  },
  markerTitleOne: {
    textTransform: "uppercase",
    fontWeight: 600,
    display: "block",
    color: theme.palette.aresBlue.main,
    textShadow:
      "-2px 0px 1px #fff, 0 2px 1px #fff, 2px 0 1px #fff, 0 0px 1px #fff",
    lineHeight: "12px",
  },
  markerTitleTwo: {
    fontWeight: 600,
    display: "block",
    color: theme.palette.aresBlue.main,
    textShadow:
      "-2px 0px 1px #fff, 0 2px 1px #fff, 2px 0 1px #fff, 0 0px 1px #fff",
    lineHeight: "25px",
    fontSize: "16px",
  },
  markerTitleThree: {
    fontWeight: 600,
    display: "block",
    color: theme.palette.aresBlue.main,
    textShadow:
      "-2px 0px 1px #fff, 0 2px 1px #fff, 2px 0 1px #fff, 0 0px 1px #fff",
    lineHeight: "12px",
    fontSize: "12px",
  },
  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
}))

const DepartmentCategoryPage = ({
  data: { departmentCategoryPage, departmentItems },
  pageContext: { locale, slug, breadcrumbs },
  location,
}) => {
  const classes = useStyles()

  const departments = departmentItems.edges.map(r => r.node)

  const schema = departmentCategoryPage.data.schema_json.text || ""
  const ogp = departmentCategoryPage.data.ogp_tags || ""
  const meta = {
    title: departmentCategoryPage.data.meta_title,
    description: departmentCategoryPage.data.meta_description,
    keywords: departmentCategoryPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const [selectedTeamMemberUid, setSelectedTeamMemberUid] = useState("-")
  const [selectedContactLocationUid, setSelectedContactLocationUid] =
    useState("-")
  const [contactModalOpen, setContactModalOpen] = useState(false)

  const onSelectTeamMember = teamMemberUid => {
    setSelectedTeamMemberUid(teamMemberUid)
    setContactModalOpen(true)
  }

  const onSelectContactLocation = contactLocationUid => {
    setSelectedContactLocationUid(contactLocationUid)
    setContactModalOpen(true)
  }

  const getTeamMembersCarousel = slice => {
    const teamMembers = slice.items
      .map(i => i.team_member.document)
      .filter(tm => tm)

    return (
      <TeamMembersCarousel
        teamMembers={teamMembers}
        onSelect={onSelectTeamMember}
      />
    )
  }

  const getContactLocation = slice => {
    const contactLocation = slice.items[0].location.document
    return (
      <LocationMapCard
        location={contactLocation}
        onSelect={onSelectContactLocation}
      />
    )
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />

      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              {departments.length > 1 && (
                <h1 id={departments[0].uid}>
                  {departmentCategoryPage.data.name}
                </h1>
              )}
              <br />
              {departments.map((department, index) => (
                <div
                  className={classes.departmentSection}
                  key={shortid.generate()}
                >
                  {departments.length > 1 && index > 0 && (
                    <span
                      className={classes.departmentSeparator}
                      id={department.uid}
                    />
                  )}

                  <SectionTitle
                    component={departments.length > 1 ? "h2" : "h1"}
                    style={{ padding: 0 }}
                  >
                    <div
                      className="departmentTitle"
                      style={{
                        backgroundImage: `url(${department.data.display_image.gatsbyImageData.images.fallback.src})`,
                      }}
                    >
                      <div
                        className={classes.icon}
                        style={{
                          backgroundImage: `url(${department.data.icon.gatsbyImageData.images.fallback.src})`,
                        }}
                      >
                        Icon
                      </div>
                      <span style={{ fontWeight: 700 }}>
                        {department.data.title.text}
                      </span>
                    </div>
                  </SectionTitle>
                  <br />

                  {department.data.body1 && (
                    <Navigation content={department.data.body1} />
                  )}
                  {department.data.body1 &&
                    department.data.body1.map(slice => (
                      <div key={shortid.generate()}>
                        {slice.slice_type === "simple" && (
                          <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: slice.primary.content.html,
                            }}
                          />
                        )}

                        {slice.slice_type === "team" &&
                          getTeamMembersCarousel(slice)}

                        {slice.slice_type === "map" &&
                          getContactLocation(slice)}
                      </div>
                    ))}
                </div>
              ))}
            </Grid>
            <Grid item xs={12} md={4}>
              <div>
                <Location>
                  {locationProps => (
                    <MenuItems locale={locale} slug={slug} {...locationProps} />
                  )}
                </Location>
              </div>
              <div className={`${clsx("contactHolder", classes.wideContact)}`}>
                <GeneralContact location={location} locale={locale} />
              </div>
            </Grid>
          </Grid>
        </Container>
        <ContactPopup
          locale={locale}
          location={location}
          open={contactModalOpen}
          handleClose={() => setContactModalOpen(false)}
          selectedContactLocationUid={selectedContactLocationUid}
          selectedTeamMemberUid={selectedTeamMemberUid}
        />
      </main>
    </>
  )
}

export default DepartmentCategoryPage

export const query = graphql`
  query DepartmentCategoryPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    departmentCategoryPage: prismicDepartmentCategory(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      first_publication_date
      last_publication_date
      data {
        name
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }

    departmentItems: allPrismicDepartment(
      filter: {
        data: {
          categories: { elemMatch: { category: { uid: { eq: $slug } } } }
        }
      }
      sort: { order: ASC, fields: data___order }
    ) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
            display_image {
              gatsbyImageData(layout: FIXED, width: 460, height: 65)
            }
            icon {
              gatsbyImageData(layout: FIXED, width: 60, height: 65)
            }
            body1 {
              ... on PrismicDepartmentDataBody1Simple {
                id
                slice_type
                primary {
                  content {
                    html
                    raw
                  }
                }
              }

              ... on PrismicDepartmentDataBody1Map {
                id
                slice_type
                items {
                  location {
                    document {
                      ... on PrismicContactLocation {
                        id
                        uid
                        lang
                        data {
                          title {
                            text
                          }
                          long_title {
                            text
                          }
                          description {
                            html
                          }
                          virtual_tour {
                            embed_url
                          }
                          city {
                            text
                          }
                          address {
                            text
                          }
                          coordinates {
                            latitude
                            longitude
                          }
                        }
                      }
                    }
                  }
                }
              }

              ... on PrismicDepartmentDataBody1Team {
                id
                slice_type
                items {
                  team_member {
                    document {
                      ... on PrismicTeamMember {
                        uid
                        id
                        lang
                        data {
                          specialisations {
                            specialisation: specialization {
                              document {
                                ... on PrismicSpecialisation {
                                  id
                                  uid
                                  data {
                                    title {
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }

                          name {
                            text
                          }
                          profile_image {
                            alt
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

DepartmentCategoryPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    departmentCategoryPage: PropTypes.object.isRequired,
    departmentItems: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
