import React, { useContext } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"

import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"

import makeStyles from "@material-ui/core/styles/makeStyles"

import slugify from "slugify"

import LangContext from "../contexts/LangContext"

const useStyles = makeStyles(theme => ({
  menu: {
    transition: "all 0.2s",
    listStylePosition: "inside",
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),

    "& a": {
      color: theme.palette.common.black,
      borderBottom: `1px solid ${theme.palette.aresBlue.main}`,
      position: 'relative',
      left: -5
    },

    "& span": {
      color: theme.palette.common.black,
      borderBottom: `1px solid ${theme.palette.aresBlue.main}`,
    },
    "& a:hover": {
      textDecoration: "none",
      background: theme.palette.aresBlue.main,
      color: "#fff",
    },
  },

  menuItem: {
    marginBottom: 2,
  },

  menuItemActive: {
    "& a": {
      color: theme.palette.aresBlue.main,
      borderBottom: `1px solid ${theme.palette.aresBlue.main}`,
    },
  },
}))

const Navigation = ({ content }) => {
  const classes = useStyles()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const headings = []

  content.forEach(slice => {
    if (slice.primary && slice.primary.content && slice.primary.content.raw) {
      slice.primary.content.raw.forEach(element => {
        if (
          element.text &&
          (element.type === "heading2" ||
            element.type === "heading3" ||
            element.type === "heading4" ||
            element.type === "heading5" ||
            element.type === "heading6")
        ) {
          headings.push(
            <li key={shortid.generate()} className={classes.menuItem}>
              <Link href={`#heading-${slugify(element.text)}`}>
                {element.text}
              </Link>
            </li>
          )
        }
      })
    }
  })

  if (headings.length) {
    return <>
      <Typography component="h2" variant="h2">
        {i18n.translations.summary}
      </Typography>
      <ul className={classes.menu}>{headings}</ul>
    </>
  }

  return <></>
}

export default Navigation

Navigation.propTypes = {
  content: PropTypes.array.isRequired,
}
